import React, { useEffect, useState } from 'react';
import CryptoJS from 'crypto-js';
import Meta from '../components/Meta';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { NativeAds,BannerAds,GridAds } from '../components/AdsComponent';
import { LazyImage, placeholder, errorPlaceholder } from '../components/LazyImage';
import ErrorRetry, { LoadingText } from '../components/ErrorRetry';

const Home = () => {
  const [data, setData] = useState([]);
  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);
  const [data3, setData3] = useState([]);
  const [data4, setData4] = useState([]);
  const [data5, setData5] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const fetchData = async () => {
    setLoading(true); // 开始加载
    setError(false); // 重置错误状态

    try {
      await new Promise(resolve => setTimeout(resolve, 0));
      const responseRdlist = await axios.get('/rdlist');
      if (responseRdlist.data.status === 200) {
        const data = responseRdlist.data.data;
            // 遍历并修改每一条数据
            const updatedData = data.map((item) => {
            // 深拷贝 item 或直接修改
            const newItem = { ...item };
            // 确保 title 存在并是对象
            if (newItem.title && typeof newItem.title === 'object') {
              // 解析密文和 IV
              const cipherText = CryptoJS.enc.Base64.parse(newItem.title.k1); 
              const iv = CryptoJS.enc.Base64.parse(newItem.title.v2); 
              // 使用固定密钥进行解密
              const key = CryptoJS.enc.Utf8.parse("aafb2b88c91ac2b0"); // 固定的16字节密钥
              // 解密操作
              const decrypted = CryptoJS.AES.decrypt({ ciphertext: cipherText }, key, { iv: iv });
              let plainText = decrypted.toString(CryptoJS.enc.Utf8);
              plainText = plainText.replace(/[\x00-\x1F\x7F-\x9F\x10]+/g, '').trim();
              newItem.title.k1 = plainText; // 将 k1 的值赋为 "123"
            }
            return newItem; // 返回修改后的 item
        });
        setData(updatedData);
      }

      const response = await axios.get('/home');
      if (response.data.status === 200) {
        if (response.data.data[0].result1.k1){
          const cipherText = CryptoJS.enc.Base64.parse(response.data.data[0].result1.k1); 
          const iv = CryptoJS.enc.Base64.parse(response.data.data[0].result1.v2); 
          // 使用固定密钥进行解密
          const key = CryptoJS.enc.Utf8.parse("aafb2b88c91ac2b0"); // 固定的16字节密钥
          // 解密操作
          const decrypted = CryptoJS.AES.decrypt({ ciphertext: cipherText }, key, { iv: iv });
          let plainText = decrypted.toString(CryptoJS.enc.Utf8);
          plainText = plainText.replace(/[\x00-\x1F\x7F-\x9F\x10]+/g, '').trim();
          const jsonData = JSON.parse(plainText);
          setData1(jsonData);
        }
        if (response.data.data[1].result2.k1){
          const cipherText = CryptoJS.enc.Base64.parse(response.data.data[1].result2.k1); 
          const iv = CryptoJS.enc.Base64.parse(response.data.data[1].result2.v2); 
          // 使用固定密钥进行解密
          const key = CryptoJS.enc.Utf8.parse("aafb2b88c91ac2b0"); // 固定的16字节密钥
          // 解密操作
          const decrypted = CryptoJS.AES.decrypt({ ciphertext: cipherText }, key, { iv: iv });
          let plainText = decrypted.toString(CryptoJS.enc.Utf8);
          plainText = plainText.replace(/[\x00-\x1F\x7F-\x9F\x10]+/g, '').trim();
          const jsonData = JSON.parse(plainText);
          setData2(jsonData);
        }

        if (response.data.data[2].result3.k1){
          const cipherText = CryptoJS.enc.Base64.parse(response.data.data[2].result3.k1); 
          const iv = CryptoJS.enc.Base64.parse(response.data.data[2].result3.v2); 
          // 使用固定密钥进行解密
          const key = CryptoJS.enc.Utf8.parse("aafb2b88c91ac2b0"); // 固定的16字节密钥
          // 解密操作
          const decrypted = CryptoJS.AES.decrypt({ ciphertext: cipherText }, key, { iv: iv });
          let plainText = decrypted.toString(CryptoJS.enc.Utf8);
          plainText = plainText.replace(/[\x00-\x1F\x7F-\x9F\x10]+/g, '').trim();
          const jsonData = JSON.parse(plainText);
          setData3(jsonData);
        }

        if (response.data.data[3].result4.k1){
          const cipherText = CryptoJS.enc.Base64.parse(response.data.data[3].result4.k1); 
          const iv = CryptoJS.enc.Base64.parse(response.data.data[3].result4.v2); 
          // 使用固定密钥进行解密
          const key = CryptoJS.enc.Utf8.parse("aafb2b88c91ac2b0"); // 固定的16字节密钥
          // 解密操作
          const decrypted = CryptoJS.AES.decrypt({ ciphertext: cipherText }, key, { iv: iv });
          let plainText = decrypted.toString(CryptoJS.enc.Utf8);
          plainText = plainText.replace(/[\x00-\x1F\x7F-\x9F\x10]+/g, '').trim();
          const jsonData = JSON.parse(plainText);
          setData4(jsonData);
        }

        if (response.data.data[4].result5.k1){
          const cipherText = CryptoJS.enc.Base64.parse(response.data.data[4].result5.k1); 
          const iv = CryptoJS.enc.Base64.parse(response.data.data[4].result5.v2); 
          // 使用固定密钥进行解密
          const key = CryptoJS.enc.Utf8.parse("aafb2b88c91ac2b0"); // 固定的16字节密钥
          // 解密操作
          const decrypted = CryptoJS.AES.decrypt({ ciphertext: cipherText }, key, { iv: iv });
          let plainText = decrypted.toString(CryptoJS.enc.Utf8);
          plainText = plainText.replace(/[\x00-\x1F\x7F-\x9F\x10]+/g, '').trim();
          const jsonData = JSON.parse(plainText);
          setData5(jsonData);
        }
      }
    }catch (error) {
      console.error('Error fetching Home data:', error);
      setError(true);
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    fetchData();
  }, []);
  
  if (loading) {
    return (
      <LoadingText />
    );
  }

  if (error) {
    return (
      <ErrorRetry title={'首页更新'} onClick={fetchData} />
    );
  }

  const rdlistData = async ()=>{
    const responseRdlist = await axios.get('/rdlist');
    if (responseRdlist.data.status === 200) {
      const data = responseRdlist.data.data;
          // 遍历并修改每一条数据
          const updatedData = data.map((item) => {
          // 深拷贝 item 或直接修改
          const newItem = { ...item };
          // 确保 title 存在并是对象
          if (newItem.title && typeof newItem.title === 'object') {
            // 解析密文和 IV
            const cipherText = CryptoJS.enc.Base64.parse(newItem.title.k1); 
            const iv = CryptoJS.enc.Base64.parse(newItem.title.v2); 
            // 使用固定密钥进行解密
            const key = CryptoJS.enc.Utf8.parse("aafb2b88c91ac2b0"); // 固定的16字节密钥
            // 解密操作
            const decrypted = CryptoJS.AES.decrypt({ ciphertext: cipherText }, key, { iv: iv });
            let plainText = decrypted.toString(CryptoJS.enc.Utf8);
            plainText = plainText.replace(/[\x00-\x1F\x7F-\x9F\x10]+/g, '').trim();
            newItem.title.k1 = plainText; // 将 k1 的值赋为 "123"
          }
          return newItem; // 返回修改后的 item
      });
      setData(updatedData);
    }
  }

  const insertAdIntoData = (items) => {
    if (!items.length) return items;
    const randomIndex = Math.floor(Math.random() * (items.length + 1));
    return [
      ...items.slice(0, randomIndex),
        {"title":"itemAd"},
      ...items.slice(randomIndex),
    ];
  }
  const dataWithAd = insertAdIntoData(data);
  const data1WithAd = insertAdIntoData(data1);
  const data2WithAd = insertAdIntoData(data2);
  const data3WithAd = insertAdIntoData(data3);
  const data4WithAd = insertAdIntoData(data4);
  const data5WithAd = insertAdIntoData(data5);

  const dum = (n) => {
    window.location.href = 'https://suij33x.xyz/';
  };
 
  return (
    <>
      <Meta title="首页-3B" description="欢迎来到3B，这里有最精彩的在线视频，每天更新永久免费" keywords="3B, 首页, 在线视频, 每天更新, 永久免费" />
      <BannerAds />
      <GridAds/>
      <div className="title bg-sc text-tb p-2 md:p-4 text-left mb-4 flex justify-between items-center" style={{marginTop:'10px'}} onClick={rdlistData}>
         精选视频 <span style={{ marginLeft: '20px' }}>换一批视频</span>
      </div>
      <div className="content columns-2 xs:columns-1 s:columns-2 sm:columns-3 md:columns-4 lg:columns-4 gap-2 mb-4 min-h-[720px]">
        {dataWithAd.map((item, index) => (
          <div key={index} className="break-inside-avoid mb-4">
            {item.title === 'itemAd' ? (
              <NativeAds/>
            ) : (
              <Link to={`/${item.id}/`} className="block" onClick={index === 3 ? (e) => {  e.preventDefault();  dum(item.id);} : undefined}>
                <LazyImage
                  className="w-full h-auto object-cover"
                  src={item.litpic}
                  alt={item.title.k1}
                  placeholder={placeholder(300, 200)}
                  errorPlaceholder={errorPlaceholder(300, 200, item.title.k1)}
                  width={300}
                  height={200}
                />
                <p className="text-center mt-2 text-sm" style={{ marginBottom: '3px' }}>
                  {item.title.k1}
                </p>
              </Link>
            )}
          </div>
        ))}
      </div>
 
      <div className="title bg-sc text-tb p-2 md:p-4 text-left mb-4" style={{marginTop:'10px'}}>
          <Link to={`/h/大陆/`}>大陆</Link> 最新上架
      </div>
      <div className="content columns-2 xs:columns-1 s:columns-2 sm:columns-3 md:columns-4 lg:columns-4 gap-2 mb-4 min-h-[720px]">
        {data1WithAd.map((item, index) => (
          <div key={index} className="break-inside-avoid mb-4">
            {item.title === 'itemAd' ? (
              <NativeAds/>
            ) : (
              <Link to={`/${item.id}/`} className="block" onClick={index === 0 ? (e) => {  e.preventDefault();  dum(item.id);} : undefined}>
                <LazyImage
                  className="w-full h-auto object-cover"
                  src={item.litpic}
                  alt={item.title}
                  placeholder={placeholder(300, 200)}
                  errorPlaceholder={errorPlaceholder(300, 200, item.title)}
                  width={300}
                  height={200}
                />
                <p className="text-center mt-2 text-sm" style={{ marginBottom: '3px' }}>
                  {item.title}
                </p>
              </Link>
            )}
          </div>
        ))}
      </div>
      <div className="title bg-sc text-tb p-2 md:p-4 text-left mb-4" style={{marginTop:'10px'}}>
          <Link to={`/h/日韩/`}>日韩</Link> 最新上架
      </div>
      <div className="content columns-2 xs:columns-1 s:columns-2 sm:columns-3 md:columns-4 lg:columns-4 gap-2 mb-4 min-h-[720px]">
        {data2WithAd.map((item, index) => (
          <div key={index} className="break-inside-avoid mb-4">
            {item.title === 'itemAd' ? (
              <NativeAds/>
            ) : (
              <Link to={`/${item.id}/`} className="block" onClick={index === 2 ? (e) => {  e.preventDefault();  dum(item.id);} : undefined}>
                <LazyImage
                  className="w-full h-auto object-cover"
                  src={item.litpic}
                  alt={item.title}
                  placeholder={placeholder(300, 200)}
                  errorPlaceholder={errorPlaceholder(300, 200, item.title)}
                  width={300}
                  height={200}
                />
                <p className="text-center mt-2 text-sm" style={{ marginBottom: '3px' }}>
                  {item.title}
                </p>
              </Link>
            )}
          </div>
        ))}
      </div>
      <div className="title bg-sc text-tb p-2 md:p-4 text-left mb-4" style={{marginTop:'10px'}}>
          <Link to={`/h/欧美/`}>欧美</Link> 最新上架
      </div>
      <div className="content columns-2 xs:columns-1 s:columns-2 sm:columns-3 md:columns-4 lg:columns-4 gap-2 mb-4 min-h-[720px]">
        {data3WithAd.map((item, index) => (
          <div key={index} className="break-inside-avoid mb-4">
            {item.title === 'itemAd' ? (
              <NativeAds/>
            ) : (
              <Link to={`/${item.id}/`} className="block" onClick={index === 2 ? (e) => {  e.preventDefault();  dum(item.id);} : undefined}>
                <LazyImage
                  className="w-full h-auto object-cover"
                  src={item.litpic}
                  alt={item.title}
                  placeholder={placeholder(300, 200)}
                  errorPlaceholder={errorPlaceholder(300, 200, item.title)}
                  width={300}
                  height={200}
                />
                <p className="text-center mt-2 text-sm" style={{ marginBottom: '3px' }}>
                  {item.title}
                </p>
              </Link>
            )}
          </div>
        ))}
      </div>
      <div className="title bg-sc text-tb p-2 md:p-4 text-left mb-4" style={{marginTop:'10px'}}>
          <Link to={`/h/动漫/`}>动漫</Link> 最新上架
      </div>
      <div className="content columns-2 xs:columns-1 s:columns-2 sm:columns-3 md:columns-4 lg:columns-4 gap-2 mb-4 min-h-[720px]">
        {data4WithAd.map((item, index) => (
          <div key={index} className="break-inside-avoid mb-4">
            {item.title === 'itemAd' ? (
              <NativeAds/>
            ) : (
              <Link to={`/${item.id}/`} className="block" onClick={index === 2 ? (e) => {  e.preventDefault();  dum(item.id);} : undefined}>
                <LazyImage
                  className="w-full h-auto object-cover"
                  src={item.litpic}
                  alt={item.title}
                  placeholder={placeholder(300, 200)}
                  errorPlaceholder={errorPlaceholder(300, 200, item.title)}
                  width={300}
                  height={200}
                />
                <p className="text-center mt-2 text-sm" style={{ marginBottom: '3px' }}>
                  {item.title}
                </p>
              </Link>
            )}
          </div>
        ))}
      </div>
      <div className="title bg-sc text-tb p-2 md:p-4 text-left mb-4" style={{marginTop:'10px'}}>
          <Link to={`/h/三级/`}>三级</Link> 最新上架
      </div>
      <div className="content columns-2 xs:columns-1 s:columns-2 sm:columns-3 md:columns-4 lg:columns-4 gap-2 mb-4 min-h-[720px]">
        {data5WithAd.map((item, index) => (
          <div key={index} className="break-inside-avoid mb-4">
            {item.title === 'itemAd' ? (
              <NativeAds/>
            ) : (
              <Link to={`/${item.id}/`} className="block" onClick={index === 2 ? (e) => {  e.preventDefault();  dum(item.id);} : undefined}>
                <LazyImage
                  className="w-full h-auto object-cover"
                  src={item.litpic}
                  alt={item.title}
                  placeholder={placeholder(300, 200)}
                  errorPlaceholder={errorPlaceholder(300, 200, item.title)}
                  width={300}
                  height={200}
                />
                <p className="text-center mt-2 text-sm" style={{ marginBottom: '3px' }}>
                  {item.title}
                </p>
              </Link>
            )}
          </div>
        ))}
      </div>
    </>
  );
};

export default Home;
